import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Notification from "../components/Notification"

const Krankheitsbilder = () => (
  <Layout>
    <SEO title="TDCS Rosenheim" />
    <Notification />

    <h1>Krankheitsbilder</h1>

    <h2>Schmerzzustände</h2>

    <p>
      Schmerzen der Wirbelsäule (HWS, BWS, LWS, Sakrum), chronische Schmerzen
      nach Bandscheibenoperationen, Unfallschäden und Operationsfolgen im
      Bereich von Wirbelsäule/Nervengeflechten/Nerven Gelenkschmerzen (Knie,
      Schultern, kleine Wirbelgelenke, Finger), Gesichtsschmerzen,
      Trigeminusneuralgie Kopfschmerzen aller Art, rheumatisch bedingte
      Schmerzen, vom sympathischen Nervensystem unterhaltene Schmerzen,
      Schmerzen nach Schlaganfall, nach Querschnittlähmung, nach Hirnverletzung,
      Krebsschmerzen,  Schmerzen nach Gürtelrose, Stumpf- und Phantomschmerzen,
      Thalamusschmerzen, gefäßbedingte Schmerzen, Fibromyalgiesyndrom,
      psychogene und psychosomatische Schmerzen, somatoforme Störungen,
      algogenes Psychosyndrom, somatisierte Depressionen und Ängste.
    </p>

    <p>
      Mit schwachem Gleichstrom, der auf die Gehirnzellen einwirkt, lassen sich
      chronische Schmerzen und Migränebeschwerden reduzieren. Durch den
      Schädelknochen hindurch kann der Strom die Erregbarkeit der Hirnzellen
      verändern und damit die Schmerzwahrnehmung mindern. Neurowissenschaftler
      der Universität Göttingen konnten die Wirksamkeit der
      Gleichstromstimulation beim Menschen belegen. Knapp 20 Minuten lang
      stimulieren die Forscher mit schwachem Gleichstrom durch den
      Schädelknochen hindurch (transkraniell) die Nervenzellen in der äußeren
      Hirnrinde. Es kribbelt leicht oder juckt dort, wo die Elektroden die
      Kopfhaut berühren. Wie die Strombehandlung auf chronische Schmerzen und
      Migräneattacken wirkt, ist Forschungsthema der Göttinger Neurophysiologen.
      Durch die transkranielle Gleichstromstimulation (tDCS) verändert sich die
      elektrische Ladung an der Nervenzellmembran. Das verstärkt oder dämpft die
      Erregbarkeit der Gehirnzellen. Bei einer verminderten Erregbarkeit sinkt
      die Schmerzwahrnehmung. Wird ausreichend lange stimuliert, halten diese
      Veränderungen auch nach der Behandlung an. Ein bis zwei Wochen lang
      behandelt das Forscherteam täglich für 15 bis 20 Minuten das Gehirn ihrer
      Schmerzpatienten mit Strom. Alternativ setzen die Wissenschaftler
      magnetische Impulse in der repetitiven transkraniellen Magnetstimulation
      (rTMS) ein. „Wir konnten in unseren Versuchen eine positive Wirkung von
      tDCS und rTMS auf chronische Schmerzen, künstlich mit dem Laser
      verursachte Schmerzen und Migräne nachweisen“, erklärt Projektleiter
      Professor Walter Paulus. Das über Jahrzehnte herrschende Dogma, dass sich
      unser Gehirn im Erwachsenenalter nicht mehr verändert, weil die
      Nervenzellen nicht mehr nachwachsen und keine neuen Schaltkontakte
      knüpfen, gilt als widerlegt. Forschungsergebnisse zeigen, dass das
      zentrale Nervensystem bis ins hohe Alter sehr wohl fähig ist, sich zu
      verändern und anzupassen. Die sogenannte Neuroplastizität
      (Veränderbarkeit) spielt eine wichtige Rolle für Lern- und
      Gedächtnisprozesse. Bis heute sind 62 klinische Studien eingeschlossen,
      von denen 17 weniger als 10 Patienten untersuchten.
    </p>

    <p>
      Unter chronischen Schmerzen finden sich neuropathischer Schmerz, Migräne,
      Rückenschmerzen und postoperative Schmerzen und Fibromyalgie.Das am
      häufigsten verwendete Protokoll: 20-minütige anodische Stimulation des
      primären Motorcortex an fünf aufeinanderfolgenden Tagen; signifikante
      Nacheffekte von zwei bis sechs Wochen, zum Beispiel berichteten 13 von 20
      Patienten nach fünf Tagen eine Schmerzreduktion um über 30 Prozent.
    </p>

    <a href="https://clinicaltrials.gov/ct2/results?cond=tdcs+and+chronic+pain">
      Übersicht klinischer Studien
    </a>
    <br />
    <br />
    <br />

    <h2>Migräne</h2>
    <p>
      Die Umstrukturierungen in den Gehirnzellen können beispielsweise dazu
      führen, dass die Zelle die unzähligen und ständig auf sie einströmenden
      Signale nicht mehr richtig verarbeiten kann. So ist bei Migränepatienten
      die Erregbarkeit der Nervenzellen in der für das Sehen zuständigen
      Hirnregion erhöht. „Mit der Gleichstromstimulation wollen wir die
      Übererregbarkeit drosseln und so Migräneattacken verhindern“, erklärt
      Paulus den Wirkmechanismus. In weiteren klinischen Studien mit Schmerz-
      und Migränepatienten untersuchen die Göttinger Wissenschaftler, wie sie
      die Gleichstromtherapie in ein akzeptiertes und sicheres therapeutisches
      Verfahren umsetzen können. Paulus: „Dann stünde zahlreichen Betroffenen
      mit chronischen Schmerzen und Migräne eine wirksame Therapiealternative
      zur Verfügung.“
    </p>

    <h2>Tinnitus</h2>
    <p>
      Tinnitus ist die Wahrnehmung von Schall ohne externe akustische
      Stimulation. Die transkranielle Gleichstromstimulation (tDCS) hat
      vielversprechende, wenn auch heterogene therapeutische Ergebnisse für
      Tinnitus gezeigt. Eine vorliegende Studie zielt darauf ab, die jüngsten
      Fortschritte bei der Anwendung von tDCS zur Behandlung von Tinnitus zu
      überprüfen. Darüber hinaus werden die klinische Wirksamkeit und die
      Hauptwirkungsmechanismen von tDCS bei der Unterdrückung von Tinnitus
      diskutiert. Diese Studie wurde gemäß den PRISMA-Richtlinien durchgeführt.
      Die Datenbanken von PubMed (1980–2018), Embase (1980–2018), PsycINFO
      (1850–2018), CINAHL, Web of Science, BIOSIS Previews (1990–2018),
      Cambridge Scientific Abstracts (1990–2018) und Google Gelehrter
      (1980–2018) unter Verwendung der festgelegten Suchbegriffe wurden benutzt.
      Das Datum der letzten Suche war der 20. Mai 2018. Die randomisierten
      kontrollierten Studien, in denen mindestens ein vor und nach der
      tDCS-Intervention gemessenes therapeutisches Ergebnis bewertet wurde,
      wurden in die endgültige Analyse einbezogen.
    </p>
    <p>
      Für Tinnitus wurden verschiedene tDCS-Protokolle verwendet, die von
      einzelnen bis zu wiederholten Sitzungen (bis zu 10) reichten und aus einer
      täglichen Einzelsitzung von 15 bis 20 Minuten und Stromstärken von 1 bis 2
      mA bestanden. Dorsolateraler präfrontaler Kortex (DLPFC) und auditorischer
      Kortex sind die Hauptziele der Stimulation. Sowohl einzelne als auch
      wiederholte Sitzungen zeigten moderate bis signifikante Behandlungseffekte
      auf die Tinnitus-Symptome. Zusätzlich zur Verbesserung der
      Tinnitus-Symptome zeigten die tDCS- Interventionen, insbesondere die
      bifrontale DLPFC, vorteilhafte Ergebnisse bei Depressionen und
      Angstzuständen, die mit Tinnitus einhergehen. Heterogenitäten in der Art
      des Tinnitus, der tDCS-Geräte, der Protokolle und des Stimulationsortes
      erschwerten die systematische Überprüfung der Literatur. Die aktuellen
      Erkenntnisse zeigen jedoch, dass tDCS als zusätzliche oder ergänzende
      Behandlung für hartnäckigen Tinnitus entwickelt werden kann. TDCS kann
      eine sichere und kostengünstige Behandlung für Tinnitus in der
      Kurzzeitanwendung sein. Die aktuelle Literatur zeigt eine mäßige bis
      signifikante therapeutische Wirksamkeit von tDCS bei Tinnitus-Symptomen.
    </p>

    <h2>Epilepsie</h2>
    <p>
      4 Epilepsie. Wenige und ungünstig angelegte Studien untersuchen derzeit
      nur assoziierte Symptome der Epilepsie, nicht aber die Häufigkeit von
      Anfällen. Übersicht:
      https://clinicaltrials.gov/ct2/results?cond=tDCS+Epilepsy
    </p>

    <h2>Morbus Parkinson</h2>
    <p>
      Bis dato72 Publikationen eingeschlossen, darunter 15 ursprüngliche
      klinische Studien mit insgesamt 225 Patienten. Untersuchte Symptome:
      motorische Kontrolle, Kinematik, Arbeitsgedächtnis, verbale Gewandtheit,
      exekutive und kognitive Funktionen, Gang und Motorleistung. Randomisierte
      Crossover -Studie zeigte nach fünf Sitzungen anodischer tDCS des
      Motorcortex bei zehn Patienten Verbesserungen von motorischen Symptomen,
      wie Gang. Sie blieben auch nach einem Monat bestehen, zum Beispiel
      schnitten Patienten signifikant besser ab beim Stand Walk Sit Test (SWS,
      Anzahl und Dauer der Schritte) und die Parkinson Disease Rating Scale
      (MDS-UPDRS) verbesserte sich. Der Einfluss auf nicht-motorische Symptome
      ist noch zu untersuchen. Es gibt eine Übersicht aktuell laufender
      randomisierter klinischer Studien, darunter Studien mit mehr als
      40Probanden:
      https://clinicaltrials.gov/ct2/results?cond=tdcs+and+Parkinson&amp;term=&amp;cntry=&amp;state=&amp;city=
      &amp;dist=
    </p>

    <h2>Multipler Sklerose</h2>
    <p>
      Fehlgeleitete neuroplastische Veränderungen wirken jedoch nachteilig.
      Hirnforscher nehmen an, dass sie die Ursache für viele chronische
      Schmerzsyndrome, Migräne, Tinnitus oder neurologische Erkrankungen sind.
      Sie suchen daher nach Lösungen, wie sie die Neuroplastizität beeinflussen
      und therapeutisch nutzen können. Eine der Lösungen ist tDCS. Ein sehr
      schwacher, kontinuierlicher Gleichstrom stimuliert das Gehirn für mehrere
      Minuten. Die Behandlung ist schmerzlos und die Patienten sind bei
      Bewusstsein. In klinischen Studien untersuchen Forscher die Wirksamkeit
      bei Schmerzen, Epilepsie, Morbus Parkinson, Multipler Sklerose, Spastik
      und nach einem Schlaganfall. Dieses sanfte Kribbeln verändert die
      Gehirnzellen. Die Umstrukturierungen in den Gehirnzellen können
      beispielsweise dazu führen, dass die Zelle die unzähligen und ständig auf
      sie einströmenden Signale nicht mehr richtig verarbeiten kann. So ist bei
      Migränepatienten die Erregbarkeit der Nervenzellen in der für das Sehen
      zuständigen Hirnregion erhöht. „Mit der Gleichstromstimulation wollen wir
      die Übererregbarkeit drosseln und so Migräneattacken verhindern“, erklärt
      Paulus den Wirkmechanismus. In weiteren klinischen Studien mit Schmerz-
      und Migränepatienten untersuchen die Göttinger Wissenschaftler, wie sie
      die Gleichstromtherapie in ein akzeptiertes und sicheres therapeutisches
      Verfahren umsetzen können. Paulus: „Dann stünde zahlreichen Betroffenen
      mit chronischen Schmerzen und Migräne eine wirksame Therapiealternative
      zur Verfügung.“
    </p>

    <h2>Spastik, Dystonie, Ataxie und nach einem Schlaganfall</h2>
    <p>
      Fehlgeleitete neuroplastische Veränderungen wirken jedoch nachteilig.
      Hirnforscher nehmen an, dass sie die Ursache für viele chronische
      Schmerzsyndrome, Migräne, Tinnitus oder neurologische Erkrankungen sind.
      Sie suchen daher nach Lösungen, wie sie die Neuroplastizität beeinflussen
      und therapeutisch nutzen können. Eine der Lösungen ist tDCS. Ein sehr
      schwacher, kontinuierlicher Gleichstrom stimuliert das Gehirn für mehrere
      Minuten. Die Behandlung ist schmerzlos und die Patienten sind bei
      Bewusstsein. In klinischen Studien untersuchen Forscher die Wirksamkeit
      bei Schmerzen, Epilepsie, Morbus Parkinson, Multipler Sklerose, Spastik
      und nach einem Schlaganfall. Dieses sanfte Kribbeln verändert die
      Gehirnzellen. Die Umstrukturierungen in den Gehirnzellen können
      beispielsweise dazu führen, dass die Zelle die unzähligen und ständig auf
      sie einströmenden Signale nicht mehr richtig verarbeiten kann. So ist bei
      Migränepatienten die Erregbarkeit der Nervenzellen in der für das Sehen
      zuständigen Hirnregion erhöht. „Mit der Gleichstromstimulation wollen wir
      die Übererregbarkeit drosseln und so Migräneattacken verhindern“, erklärt
      Paulus den Wirkmechanismus. In weiteren klinischen Studien mit Schmerz-
      und Migränepatienten untersuchen die Göttinger Wissenschaftler, wie sie
      die Gleichstromtherapie in ein akzeptiertes und sicheres therapeutisches
      Verfahren umsetzen können. Paulus: „Dann stünde zahlreichen Betroffenen
      mit chronischen Schmerzen und Migräne eine wirksame Therapiealternative
      zur Verfügung.“
    </p>

    <h2>Aphasie und nach einem Schlaganfall</h2>
    <p>
      Fehlgeleitete neuroplastische Veränderungen wirken jedoch nachteilig.
      Hirnforscher nehmen an, dass sie die Ursache für viele chronische
      Schmerzsyndrome, Migräne, Tinnitus oder neurologische Erkrankungen sind.
      Sie suchen daher nach Lösungen, wie sie die Neuroplastizität beeinflussen
      und therapeutisch nutzen können. Eine der Lösungen ist tDCS. Ein sehr
      schwacher, kontinuierlicher Gleichstrom stimuliert das Gehirn für mehrere
      Minuten. Die Behandlung ist schmerzlos und die Patienten sind bei
      Bewusstsein. In klinischen Studien untersuchen Forscher die Wirksamkeit
      bei Schmerzen, Epilepsie, Morbus Parkinson, Multipler Sklerose, Spastik
      und nach einem Schlaganfall. Dieses sanfte Kribbeln verändert die
      Gehirnzellen. Die Umstrukturierungen in den Gehirnzellen können
      beispielsweise dazu führen, dass die Zelle die unzähligen und ständig auf
      sie einströmenden Signale nicht mehr richtig verarbeiten kann. So ist bei
      Migränepatienten die Erregbarkeit der Nervenzellen in der für das Sehen
      zuständigen Hirnregion erhöht. „Mit der Gleichstromstimulation wollen wir
      die Übererregbarkeit drosseln und so Migräneattacken verhindern“, erklärt
      Paulus den Wirkmechanismus. In weiteren klinischen Studien mit Schmerz-
      und Migränepatienten untersuchen die Göttinger Wissenschaftler, wie sie
      die Gleichstromtherapie in ein akzeptiertes und sicheres therapeutisches
      Verfahren umsetzen können. Paulus: „Dann stünde zahlreichen Betroffenen
      mit chronischen Schmerzen und Migräne eine wirksame Therapiealternative
      zur Verfügung.“
    </p>

    <h2>Depression</h2>
    <p>
      Wirksamkeit und Effektivität von TMS in der Behandlung ist durch die FDA
      (Food and Drug Administration) und CE Kennzeichen (Conformité Européenne)
      anerkannt; Behandlung mit tDCS wird untersucht, auf Grund dessen wird es
      nur von den meißten privaten Krankenkassen gezahlt.Übersichtsarbeit
      identifiziert 39 klinische Studien mit 988 Patienten. Übersichtsarbeit
      spricht Level B Empfehlung aus für antidepressive Wirksamkeit von
      anodischer tDCS bei zehn aufeinanderfolgenden, täglichen Sitzungen.
      Kombination mit Pharmakotherapie zeigt mögliche additive Interaktion
      zwischen tDCS und Medikation. Langfristige Wirksamkeit getestet bei elf
      Patienten nach zehntägiger Behandlung. Studie hatte jedoch eine hohe
      Drop-out Rate. Laufende klinische Studien zum Einsatz von tDCS in
      der.Therapie(&gt;20):
      https://clinicaltrials.gov/ct2/results?cond=tdcs+and+depression&amp;recrs=a&amp;age_v=&amp;gndr=&amp;ty
      pe=&amp;rslt=&amp;phase=1&amp;phase=2&amp;phase=5&amp;Search=Apply
    </p>

    <h2>Affektive Störungen</h2>
    <p>
      Die ätiopathogenen und physiopathologischen Mechanismen der bipolaren
      Depression sind noch nicht vollständig bekannt. Ein wichtiger Faktor
      scheint jedoch das „erblich-genetische“ zu sein. Während das allgemeine
      Risiko für die Entwicklung der bipolaren Störung in der
      Allgemeinbevölkerung zwischen 1% und 2% liegt, steigt es bei Verwandten
      ersten Grades eines bipolaren „Trägers“ auf 9%. Die Konformität zwischen
      homozygoten Zwillingen variiert zwischen 40% und 50% und die Heritabilität
      (Anteil des Krankheitsrisikos in der Bevölkerung aufgrund genetischer
      Variation) kann 80% bis 85% erreichen. Unter dem Gesichtspunkt der
      Bildgebung weisen mehrere Studien auf das Engagement einiger an der
      affektiven Regulation beteiligter Strukturen hin, wie PFC, Gyrus cinguli
      anterior und Amygdala. Neurophysiologische Studien bei bipolaren Patienten
      weisen wiederum auf Defizite bei Führungskräften und Aufmerksamkeit hin,
      was die Idee eines Engagements der PFC bestätigt.{" "}
    </p>
    <p>
      TDCS bei bipolarer Depression. In einer kürzlich durchgeführten Studie mit
      31 Patienten (14 mit bipolarer Depression und 17 mit unipolarer
      Depression) wurden alle Probanden einem bestimmten Protokoll unterzogen: 5
      Sitzungen mit tDCS mit jeweils 20 Minuten und anodischer Stimulation über
      die linke DLPFC. Die Behandlung wurde von allen gut vertragen und es
      wurden keine signifikanten Nebenwirkungen beobachtet. Nach der fünften
      tDCS-Sitzung nahmen die depressiven Symptome in beiden Gruppen ab und die
      positiven Wirkungen hielten einen Monat an. Loo et al., bei denen acht
      Patienten mit bipolarer Depression (vier in jeder Gruppe) rekrutiert
      wurden, zeigten in der RCT-Phase keine signifikanten Ergebnisse, jedoch
      waren die Ergebnisse beim Follow-up-Ansatz aussagekräftiger.
    </p>

    <h2>PTBS</h2>
    <p>
      Übersichtsarbeit identifiziert sieben randomisierte klinische Studien mit
      mindestens zehn Patienten. Beispielstudie mit 20 Probanden: Patienten der
      Stimulationsgruppe gaben im Vergleich zur Kontrollgruppe nach zehnmaliger
      tDCS Reduktion der negativen Symptome außerdem Verbesserung der
      depressiven Symptome und kognitiver Verarbeitungsgeschwindigkeit an. MRT
      zeigte veränderte Konnektivität der Zielgebiete. Aufgrund der
      exzitatorischen Wirkung der Anode und der inhibitorischen Wirkung der
      Kathode, die in den neurophysiologischen Studien beschrieben wurden, in
      denen die Wirkung von tDCS auf die Erregbarkeit des motorischen Kortex
      untersucht wurde, wurde die Hypothese aufgestellt, dass anodisches tDCS
      über dem linken präfrontalen Kortex (hypoaktiv) in Kombination mit
      kathodischem tDCS über dem linken temporo-parietalen Übergang (hyperaktiv)
      könnte auditive Halluzinationen bei Patienten mit Schizophrenie lindern
      (für eine Übersicht siehe Mondino). In gleicher Weise wird angenommen,
      dass bifrontales tDCS mit der Anode über dem linken präfrontalen Bereich
      und der Kathode über ihrem rechten Homologen oder dem rechten
      supraorbitalen Bereich negative Symptome verringert. Basierend auf diesen
      Hypothesen haben mehrere Studien die klinischen Auswirkungen von tDCS bei
      diesen beiden Indikationen bei Schizophrenie untersucht. Der Strom wurde
      auf eine Intensität von 2 mA eingestellt und 20 Minuten lang abgegeben.
      Die Elektroden wurden gemäß dem internationalen 10/20-EEG-System über der
      Kopfhaut der Probanden platziert. Das Zentrum der Elektrode wurde auf
      einen Punkt in der Mitte zwischen T3 und P3 gelegt, um den linken
      temporo-parietalen Übergang zu stimulieren, und auf F3 (F4) oder zwischen
      F3 und FP1 (F4 und FP2), um den linken (rechten) präfrontalen Bereich zu
      stimulieren. <br />
      In einer anderen Studie wurden 20 Patienten mit überwiegend negativen
      Symptomen und stabilen Medikamenten (&gt; 3 Wochen) untersucht und in
      aktive oder scheinstimulierende Gruppen randomisiert. Die Anode wurde über
      dem linken DLPFC und die Kathode über dem rechten supraorbitalen Bereich
      platziert; tDCS wurde einmal täglich für 10 Tage geliefert. Die aktive
      Gruppe zeigte zwei Wochen nach Ende der Stimulation eine signifikante
      Verbesserung der Skala für das positive und negative Syndrom. <br />
      tDCS wird aktuell zunehmend untersucht und eingesetzt bei Patienten mit
      Schizophrenie, derzeit über 40 klinische Studien, aktuelle Übersicht:
      clinicaltrials.gov/ct2/results
    </p>

    <h2>Angst</h2>

    <h2>Rehabilitation bei Lähmungen</h2>

    <h2>Schizophrenie</h2>
    <p>
      Übersichtsarbeit identifiziert sieben randomisierte klinische Studien mit
      mindestens zehn Patienten. Beispielstudie mit 20 Probanden: Patienten der
      Stimulationsgruppe gaben im Vergleich zur Kontrollgruppe nach zehnmaliger
      tDCS Reduktion der negativen Symptome außerdem Verbesserung der
      depressiven Symptome und kognitiver Verarbeitungsgeschwindigkeit an. MRT
      zeigte veränderte Konnektivität der Zielgebiete. Aufgrund der
      exzitatorischen Wirkung der Anode und der inhibitorischen Wirkung der
      Kathode, die in den neurophysiologischen Studien beschrieben wurden, in
      denen die Wirkung von tDCS auf die Erregbarkeit des motorischen Kortex
      untersucht wurde, wurde die Hypothese aufgestellt, dass anodisches tDCS
      über dem linken präfrontalen Kortex (hypoaktiv) in Kombination mit
      kathodischem tDCS über dem linken temporo-parietalen Übergang (hyperaktiv)
      könnte auditive Halluzinationen bei Patienten mit Schizophrenie lindern
      (für eine Übersicht siehe Mondino). In gleicher Weise wird angenommen,
      dass bifrontales tDCS mit der Anode über dem linken präfrontalen Bereich
      und der Kathode über ihrem rechten Homologen oder dem rechten
      supraorbitalen Bereich negative Symptome verringert. Basierend auf diesen
      Hypothesen haben mehrere Studien die klinischen Auswirkungen von tDCS bei
      diesen beiden Indikationen bei Schizophrenie untersucht. Der Strom wurde
      auf eine Intensität von 2 mA eingestellt und 20 Minuten lang abgegeben.
      Die Elektroden wurden gemäß dem internationalen 10/20-EEG-System über der
      Kopfhaut der Probanden platziert. Das Zentrum der Elektrode wurde auf
      einen Punkt in der Mitte zwischen T3 und P3 gelegt, um den linken
      temporo-parietalen Übergang zu stimulieren, und auf F3 (F4) oder zwischen
      F3 und FP1 (F4 und FP2), um den linken (rechten) präfrontalen Bereich zu
      stimulieren.
    </p>
    <p>
      In einer anderen Studie wurden 20 Patienten mit überwiegend negativen
      Symptomen und stabilen Medikamenten (&gt; 3 Wochen) untersucht und in
      aktive oder scheinstimulierende Gruppen randomisiert. Die Anode wurde über
      dem linken DLPFC und die Kathode über dem rechten supraorbitalen Bereich
      platziert; tDCS wurde einmal täglich für 10 Tage geliefert. Die aktive
      Gruppe zeigte zwei Wochen nach Ende der Stimulation eine signifikante
      Verbesserung der Skala für das positive und negative Syndrom.
    </p>
    <p>
      tDCS wird aktuell zunehmend untersucht und eingesetzt bei Patienten mit
      Schizophrenie, derzeit über 40 klinische Studien, aktuelle Übersicht:
      clinicaltrials.gov/ct2/results
    </p>

    <h2>Demenz</h2>
    <p>
      tDCS als Zusatztherapie für Demenz. Eine signifikante Verbesserung der
      kognitiven Funktionen wurde gezeigt, die mindestens bis zu 2 Wochen nach
      anodaler tDCS- Stimulation des unteren Parietallappens bei Personen mit
      neurodegenerativen Erkrankungen anhielt, die in dieser Region einen
      Hypometabolismus zeigten. Die Verbesserung wurde bei der
      Ziel-Bildbenennungsaufgabe deutlich gezeigt, da die Genauigkeit beim
      Benennen trainierter Bilder deutlich verbessert wurde, sogar eine
      Verbesserung gegen Schein für alle getesteten Bilder nachweisbar war.
      Darüber hinaus berichteten bestimmte Familienmitglieder in
      Nachbesprechungssitzungen über positive Verhaltensänderungen, die mit
      einer aktiven parietalen Stimulation zusammenfielen und bei der
      Verabreichung einer Scheinstimulation fehlten. Die Teilnehmer verbesserten
      sich, wenn das Benennungstraining mit 30 Minuten anodischer Stimulation
      kombiniert wurde. Darüber hinaus waren die Ergebnisse für die trainierten
      Gegenstände viel größer, was unsere Hypothese bestätigt, dass die Effekte
      größer sind, wenn Stimulation mit Training kombiniert wird. Aus diesem
      Grund waren diese Ergebnisse ausreichend, um weitere Arbeiten zur
      Aufklärung der optimalen Positionierung, Position, kognitiven Aufgaben,
      Montage und Patienteneigenschaften, die für AD- und FTD- Patienten einen
      signifikanten Nutzen bringen, durchzuführen. Zum Beispiel haben Cheng et
      al. haben eine tDCS-Studie vorgeschlagen, in der untersucht wird, ob tDCS
      auch das Arbeitsgedächtnis bei Menschen mit Alzheimer-Krankheit verbessern
      kann. Wenn unsere Ergebnisse in formellen größeren klinischen Studien
      wiederholt werden, könnte sich tDCS mit Training als praktikable Therapie
      für AD und FTD herausstellen.
    </p>
    <p>
      Übersicht laufender klinische Studien (&gt; 10):
      https://clinicaltrials.gov/ct2/results?cond=tDCS+ALzheimer&amp;term=&amp;cntry=&amp;state=&amp;city=&amp;
      dist=
    </p>

    <h2>Abhängigkeiten (Alkohol, Drogen, Medikamente, Über-/Untergewicht)</h2>
    <p>
      Die Behandlung von Substanzstörungen ist normalerweise schwierig und
      herausfordernd. Der zentrale Belohnungspfad, der für den
      Abhängigkeitsmechanismus von entscheidender Bedeutung ist, umfasst das
      dopaminerge System einschließlich des mesolimbischen kortikalen ventralen
      tegmentalen Bereichs und Projektionen zum Nucleus accumbens und zum
      präfrontalen Kortex. Neuroimaging-Studien zeigten, dass die wichtige
      Funktion des präfrontalen Kortex bei Substanzstörungen eine wichtige
      kortikale Struktur im Arbeitsgedächtnis und in den Exekutivfunktionen
      darstellt, zwei kognitive Domänen, die normalerweise durch chemische
      Abhängigkeiten geschädigt werden. In der Literatur haben nur wenige
      Studien die Anwendung von tDCS zur Behandlung chemischer Abhängigkeiten
      untersucht. Nichtsdestotrotz haben diese Studien eine mögliche Rolle
      dieser Technik für die Behandlung dieser Zustände gezeigt, insbesondere
      durch Stimulierung der Aktivität des präfrontalen Kortex. Daher verdient
      die Wirksamkeit von tDCS bei der Behandlung von Substanzstörungen weitere
      Untersuchungen, wie nachstehend gezeigt.
    </p>
    <p>
      Kokain: Goriniet et al. verwendeten tDCS gegenüber dem DLPFC in zwei
      Proben von Probanden (18 kokai nabhängige Konsumenten und 18
      Kontrollpersonen), um die Auswirkungen einer Erhöhung der kortikalen
      Erregbarkeit nach rechts- oder linksanodaler Stimulation zu untersuchen.
      Die Probanden wurden randomisiert, um eine linksanodale / rechtskathodale
      Stimulation, eine rechtsanodale / linkskathodale Stimulation oder eine
      Scheinstimulation (Placebo) zu erhalten; Jede Sitzung wurde im Abstand von
      mindestens 48 Stunden durchgeführt. Die Ergebnisse zeigten, dass die
      Aktivierung des DLPFC (links und rechts) zu einer Verringerung des
      Risikoverhaltens sowohl bei Patienten als auch bei Kontrollpersonen bei
      kokainabhängigen Konsumenten führt. Die Autoren kamen zu dem Schluss, dass
      tDCS in Zukunft ein wertvolles Instrument für die Intervention bei
      Kokainkonsumenten darstellen könnte.
    </p>
    <p>
      Alkohol: Eine randomisierte scheinkontrollierte Studie, in der 13
      Probanden drei verschiedene Arten der bilateralen Stimulation von DLPFC
      mit tDCS erhielten: (1) aktives anodales linkes und kathodisches rechtes
      tDCS; (2) aktives anodales rechtes und kathodisches linkes tDCS; und (3)
      Schein-tDCS haben in beiden aktiven Gruppen im Vergleich zur Scheingruppe
      eine Verringerung des Verlangens nach Alkohol gezeigt. Darüber hinaus
      untersuchten Klausset et al. 35 randomisierte Probanden, die eine aktive
      bilaterale (links kathodal / rechts anodal über die DLPFC) repetitive
      (fünf aufeinanderfolgende Tage) tDCS (2 mA, 35 cm2, zweimal tägliche
      Stimulation für 13 Minuten mit einer 20) erhielten -min Intervall) oder
      Schein-TDCS. Sie beobachteten, dass bilaterale tDCS über DLPFC die
      Rückfallwahrscheinlichkeit bei schweren Alkoholikern verringerten und die
      Wahrnehmung der Lebensqualität verbesserten.
    </p>
    <p>
      Nikotin: Fregni et al. haben 24 Patienten untersucht, die drei
      verschiedene Zustände einer einzelnen tDCS-Sitzung in zufälliger
      Reihenfolge erhielten: Scheinstimulation, Anode auf der rechten DLPFC und
      Anode auf der linken DLPFC. Die Autoren beobachteten eine Verringerung des
      Verlangens in beiden aktiven Gruppen im Vergleich zur Scheingruppe. In
      einer anderen Studie wurden die Auswirkungen von fünf aufeinander
      folgenden tDCS- Sitzungen auf DLPFC bewertet. 27 Patienten wurden in zwei
      verschiedene Gruppen randomisiert: linke Anode und Scheinstimulation. Die
      Ergebnisse zeigten eine kleine, aber signifikante Verringerung des
      Zigarettenkonsums und des Verlangens in aktiven gegenüber Scheingruppen.
      Eine neuere Studie zeigte, dass eine anodische Stimulation über dem linken
      DLPFC den rauchbedingten negativen Effekt verbesserte, die Fissur jedoch
      nicht verbesserte. Die Autoren untersuchten 24 Raucher, die eine echte
      Sitzung und eine Schein-Sitzung von tDCS erhielten, nachdem sie an zwei
      verschiedenen Tagen über Nacht vom Rauchen Abstand genommen hatten. Sie
      applizierten 20 Minuten lang anodisches tDCS auf den linken dorsolateralen
      präfrontalen Kortex und eine Kathode auf den rechten supraorbitalen
      Bereich mit einer Stromstärke von 2,0 mA. Fecteau et al. bewerteten zwei
      fünftägige tDCS-Therapien (aktiv oder schein). Die Stimulation wurde über
      die rechte DLPFC bei 2 mA während 30 Minuten bei zwölf Erwachsenen
      abgegeben. Das Hauptergebnis war eine signifikante Verringerung der Anzahl
      der gerauchten Zigaretten, wenn die Teilnehmer aktiv wurden, im Vergleich
      zur Scheinstimulation.
    </p>
    <p>
      Lebensmittel: Fregni et al. führten eine Studie an gesunden Probanden
      durch, die über häufige Heißhungerattacken berichteten. Sie entwarfen eine
      scheinkontrollierte Crossover- Studie, bei der eine Sitzung der
      tDCS-Stimulation (2 mA, 20 Minuten) unter drei verschiedenen Bedingungen
      angewendet wurde: DLPFC anodal-links / kathodal-rechts, DLPFC
      kathodisch-rechts / anodal-links und Placebo. Sie beobachteten eine
      Verringerung des Cue-induzierten Verlangens nach Nahrung beim Vergleich
      der aktiven anodalen linken / kathodischen rechten DLPFC mit den anderen
      Gruppen und eine geringere Kalorienaufnahme beim Vergleich der aktiven
      (beide Konfigurationen) mit der Scheingruppe. Goldman et al. wendeten 1
      Sitzung tDCS (2 mA, 20 min, anodischer präfrontaler dorsolateraler Kortex
      rechts) bei gesunden Probanden an, die über Heißhunger auf Nahrung
      berichteten. Die Studie zeigte eine Verringerung des Verlangens sowohl
      unter Schein- als auch unter aktiven tDCS-Bedingungen. Darüber hinaus
      zeigten die Ergebnisse verringerte Bewertungen für bestimmte Lebensmittel,
      wenn sie mit aktivem tDCS verglichen wurden.
    </p>
    <p>
      Cannabis: Boggio et al. untersuchten die Wirkung von tDCS auf die
      Cannabisabhängigkeit. 25 Patienten wurden in drei verschiedene Gruppen
      eingeteilt: Anode links / Kathode rechts, Anode rechts / Kathode links und
      Scheinstimulation. Die Ergebnisse zeigten eine signifikante Abnahme des
      Verlangens nach Marihuana in der anodischen rechten Stimulationsgruppe.
    </p>
  </Layout>
)

export default Krankheitsbilder
